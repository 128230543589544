@tailwind base;
@tailwind components;
@tailwind utilities;

#sidebar {
    transition: ease-in-out all .3s;
    z-index: 9999;
}

#sidebar span {
    opacity: 0;
    position: absolute;
    transition: ease-in-out all .1s;
}

#sidebar:hover {
    width: 150px;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    /*shadow-2xl*/
}

#sidebar:hover span {
    opacity: 1;
}